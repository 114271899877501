import React, { useState, useEffect } from "react";
import ComplexTable from "views/admin/default/components/ComplexTable";
import { allEventsColumnsData } from "views/admin/default/variables/allEventsColumnsData";

import Card from "components/card/Card";
import { Flex, Text } from "@chakra-ui/react";

import { useHistory } from "react-router-dom";

// Yet another table
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";

// Service
import rdvService from "services/rdvService";

// Dummy
import { columnsDataDevelopment } from "views/admin/dataTables/variables/columnsData";

function Primer({ text }) {
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text fontSize="lg" fontFamily="heading" fontWeight="500">
          {text}
        </Text>
      </Flex>
    </Card>
  );
}

function AppointmentsController({ accessToken, detailed }) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);

  // Initialize history
  const history = useHistory();

  useEffect(() => {
    // Function to fetch data from the API
    const response = rdvService
      .getAll(accessToken)
      .then((res) => {
        // Log
        console.log(res);
        // If the response is not ok, throw an error
        if (!res.data) {
          throw new Error("Network response was not ok");
        }
        // Add raw data to state
        setRawData(res.data);
        // If the response.data is empty, or not an array, try again
        if (!res.data || !Array.isArray(res.data)) {
          console.log("=====> No data found!");
          throw new Error("No data found");
        }
        // Loop through data and add create a new array of objects
        const formattedData = res.data.map((item) => {
          return {
            subject: item.subject,
            // Ternary operator to check if the event is confirmed or not
            status: item.subject ? "Approved" : "Disable",
            date: "2021-09-01",
            progress: 50.5,
          };
        });

        setData(formattedData);
        setIsLoading(false);
      })
      .catch((err) => {
        // Log
        console.error(err);
        setError(err);
        setIsLoading(false);
      });

    // Clean up function to cancel fetch if component unmounts before fetch completes
    return () => {
      // Cleanup logic if needed
    };
  }, [accessToken, isLoading, data.length]); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  // A use effect that listens to hasError and navigates to the login page
  useEffect(() => {
    if (
      error &&
      error.message === "Login failed. Please check your credentials."
    ) {
      // Redirect to login page
      console.log("=====> Redirect to login page");
      history.push("/auth/sign-in");
    }
  }, [error]);

  if (isLoading) {
    return <Primer text="Loading..." />;
  }

  if (error) {
    return <Primer text={`Error: ${error}`} />;
  }

  // If detailed is true, return the DevelopmentTable component
  if (detailed) {
    return (
      <DevelopmentTable
        columnsData={columnsDataDevelopment}
        tableData={rawData}
      />
    );
  }
  return <ComplexTable columnsData={allEventsColumnsData} tableData={data} />;
}

export default AppointmentsController;

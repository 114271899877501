import React, { useEffect } from "react";
import { Box, SimpleGrid, useColorModeValue, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";

import AdvancedCalendar from "./components/AdvancedCalendar";
import WorkingDaysSelector from "./components/WorkingDaysSelector";

import {
  fetchWorkingDays,
  saveWorkingDays,
} from "../../../actions/workingDaysActions";

import {
  getWorkingDays,
  getWorkingDaysLoading,
  getWorkingDaysError,
} from "../../../selectors/workingDaysSelector";

const ServiceProviderCalendar = () => {
  const dispatch = useDispatch();
  const workingDays = useSelector(getWorkingDays);
  const loading = useSelector(getWorkingDaysLoading);
  const error = useSelector(getWorkingDaysError);

  useEffect(() => {
    dispatch(fetchWorkingDays());
  }, [dispatch]);

  const handleSaveWorkingDays = (days) => {
    dispatch(saveWorkingDays(days, workingDays[0].id));
  };

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        {/* Working days selector */}
        <WorkingDaysSelector
          workingDays={workingDays}
          onSave={handleSaveWorkingDays}
          loading={loading}
          error={error}
        />
        {/* Calendar */}
        <AdvancedCalendar workingDays={workingDays} />
      </SimpleGrid>
      {/* Other components and sections */}
    </Box>
  );
};

export default ServiceProviderCalendar;

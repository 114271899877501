// actions/workingDaysActions.js
import {
  SET_WORKING_DAYS,
  FETCH_WORKING_DAYS,
  FETCH_WORKING_DAYS_SUCCESS,
  FETCH_WORKING_DAYS_FAILURE,
} from "./types/workingDays.types";

import {
  fetchWorkingDaysAPI,
  saveWorkingDaysAPI,
} from "../services/workingDaysService";

export const setWorkingDays = (days) => ({
  type: SET_WORKING_DAYS,
  payload: days,
});

export const fetchWorkingDays = () => async (dispatch, getState) => {
  const state = getState();
  const accessToken = state.auth.accessToken;

  dispatch({ type: FETCH_WORKING_DAYS });
  try {
    const data = await fetchWorkingDaysAPI({ accessToken });
    dispatch({ type: FETCH_WORKING_DAYS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_WORKING_DAYS_FAILURE, payload: error.message });
  }
};

export const saveWorkingDays =
  (days, calendarId) => async (dispatch, getState) => {
    const state = getState();
    const accessToken = state.auth.accessToken;
    try {
      await saveWorkingDaysAPI({
        accessToken,
        days,
        calendarId,
      });
      dispatch(setWorkingDays(days));
    } catch (error) {
      console.error("Failed to save working days:", error);
    }
  };

import React, { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Icon,
  Flex,
  Textarea,
  Avatar,
  useColorModeValue,
  Tag,
  VStack,
} from "@chakra-ui/react";

// Icons
import {
  MdEventNote,
  MdPerson,
  MdLocationOn,
  MdBuild,
  MdEditNote,
} from "react-icons/md";
import { FaCalendarAlt, FaClock } from "react-icons/fa";

// Custom Components
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";

// Date Fns
import { format } from "date-fns";

// Router
import { useParams } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import {
  selectUser,
  selectAccessToken,
  selectAssociation,
} from "../../../selectors/authSelector";

// Service
import rdvService from "../../../services/rdvService";

const MediationManagement = ({ user, accessToken }) => {
  // Get the appointment ID from the URL
  // Use the appointmentId as needed
  const { appointmentId } = useParams();
  console.log("Appointment ID: ", appointmentId);

  const [notes, setNotes] = useState(
    "You can add notes here. Notes are auto saved and cannot be edited later."
  );

  const handleNoteChange = (e) => {
    setNotes(e.target.value);
  };

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  // State for loading, error, and data
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const response = rdvService
      .get({ accessToken, pk: appointmentId })
      .then((res) => {
        // Log
        console.log(res);
        // If the response is not ok, throw an error
        if (!res.data) {
          throw new Error("Network response was not ok");
        }
        // Add raw data to state
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // Log
        console.error(err);
        setError(err);
        setIsLoading(false);
      });

    // Clean up function to cancel fetch if component unmounts before fetch completes
    return () => {
      // Cleanup logic if needed
    };
  }, [appointmentId, accessToken]); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {isLoading && <Text>Loading...</Text>}
      {error && <Text>{error.message}</Text>}
      {/* If not loading and data is not null */}
      {data && (
        <>
          <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px" mb="20px">
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdEventNote}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Date"
              value={data.date}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon w="32px" h="32px" as={MdPerson} color={brandColor} />
                  }
                />
              }
              name="Mediator"
              value={`${data.mediator.first_name} ${data.mediator.last_name}`}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdLocationOn}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Location"
              value={`${data.location.name}`}
            />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px" mb="20px">
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon w="32px" h="32px" as={MdBuild} color={brandColor} />
                  }
                />
              }
              name="Service"
              value={`Type: ${data.service.name}`}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdEditNote}
                      color={brandColor}
                    />
                  }
                />
              }
              name="File Number"
              value={data.file_number}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdEventNote}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Time"
              value={data.time}
            />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
            <Box bg={boxBg} p="20px" borderRadius="10px">
              <Text fontSize="xl" fontWeight="bold">
                Nature
              </Text>
              <Text>{data.nature}</Text>
            </Box>
            <Box bg={boxBg} p="20px" borderRadius="10px">
              <Text fontSize="xl" fontWeight="bold">
                Legal Context
              </Text>
              <Text>{data.legal_context}</Text>
            </Box>
            <Box bg={boxBg} p="20px" borderRadius="10px">
              <Text fontSize="xl" fontWeight="bold">
                Subject
              </Text>
              <Text>{data.subject}</Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <Box bg={boxBg} p="20px" borderRadius="10px">
              <Text fontSize="xl" fontWeight="bold">
                RDV Sessions
              </Text>
              <VStack align="start" spacing="20px">
                {data.rdv_sessions.map((session, index) => (
                  <Box
                    key={session.id}
                    bg={boxBg}
                    p="20px"
                    borderRadius="10px"
                    w="100%"
                  >
                    <Flex justify="space-between" align="center" mb="10px">
                      <Text fontSize="lg" fontWeight="bold">
                        Session {session.id} ({session.calendar_block.title})
                      </Text>
                      <Tag
                        size="lg"
                        colorScheme={session.type === "Info" ? "blue" : "green"}
                      >
                        {session.type}
                      </Tag>
                    </Flex>
                    <Text mb="10px">
                      <Flex justify="start" align="center">
                        <FaCalendarAlt /> {/* empty box, 4px width */}
                        <Box w="4px" />
                        {format(
                          new Date(session.calendar_block.start_date),
                          "MMMM dd, yyyy"
                        )}{" "}
                        {/* empty box, 4px width */}
                        <Box w="4px" />
                        <FaClock /> {/* empty box, 4px width */}
                        <Box w="4px" />
                        {format(
                          new Date(session.calendar_block.start_date),
                          "hh:mm a"
                        )}
                      </Flex>
                    </Text>
                    <Text mb="10px">
                      <Flex justify="start" align="center">
                        <FaCalendarAlt /> {/* empty box, 4px width */}
                        <Box w="4px" />
                        {format(
                          new Date(session.calendar_block.end_date),
                          "MMMM dd, yyyy"
                        )}{" "}
                        {/* empty box, 4px width */}
                        <Box w="4px" />
                        <FaClock /> {/* empty box, 4px width */}
                        <Box w="4px" />
                        {format(
                          new Date(session.calendar_block.end_date),
                          "hh:mm a"
                        )}
                      </Flex>
                    </Text>
                    {/* Editable note box */}
                    <Textarea
                      value={notes}
                      onChange={handleNoteChange}
                      size="sm"
                      bg="white"
                      color="black"
                    />
                  </Box>
                ))}
              </VStack>
            </Box>
          </SimpleGrid>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  accessToken: selectAccessToken(state),
});

export default connect(mapStateToProps)(MediationManagement);

export const allEventsColumnsData = [
  {
    Header: "Sujet de la MF",
    accessor: "subject",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

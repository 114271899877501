import React from "react";
import { Icon } from "@chakra-ui/react"; // Import Icon component from Chakra UI
import { AiOutlineReload } from "react-icons/ai";

const eventStyleGetter = (event, start, end, isSelected) => {
  // Define styles for recurring events
  const recurringEventStyles = {
    backgroundColor: "#ffcccb", // Subtle color for recurring events
    borderRadius: "0px",
    opacity: 0.8,
    color: "#333", // Darker text color
    border: "0px",
    display: "block",
    position: "relative", // To position the react icon
    paddingLeft: "20px", // Adjust the left padding for the react icon
  };

  // Define styles for the react icon
  const iconStyle = {
    position: "absolute",
    left: "4px", // Adjust the left position of the icon
    top: "50%",
    transform: "translateY(-50%)",
  };

  // Check if the event is recurring
  console.log(event);
  if (event.recurring) {
    // If the event is recurring, add a react icon to the event
    return {
      style: recurringEventStyles,
      // Render a react icon within the event block
      children: <Icon as={AiOutlineReload} style={iconStyle} color="#999" />,
    };
  }

  // Return default style for non-recurring events
  return {
    style: {
      backgroundColor: "#ff706d",
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    },
  };
};

export default eventStyleGetter;

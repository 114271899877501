// Import sample data
import sampleEvents from "../variables/sampleEvents";

import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchCalendarEvents } from "actions/calendarActions";
import {
  getCalendarEvents,
  getCalendarLoading,
  getCalendarError,
} from "../../../../selectors/calendarSelector";
import {
  Box,
  useColorModeValue,
  useTheme,
  Tooltip,
  Text,
  Icon,
} from "@chakra-ui/react";

import { MdInfo } from "react-icons/md";

// Event styler
import eventStyleGetter from "./eventStyleGetter";

// Recurring rule calendar utilities
import { RRule, RRuleSet, rrulestr } from "rrule";

// New event form
import EventForm from "./EventForm";

// Date and Time localization
const localizer = momentLocalizer(moment);

function initializeDateWithTimeString(timeString, month = 0, day = 1) {
  // Get the current year
  const currentYear = new Date().getFullYear();

  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Create a new Date object with the current year and the parsed time
  const date = new Date(
    Date.UTC(currentYear, month, day, hours, minutes, seconds)
  );

  return date;
}

const AdvancedCalendar = ({ workingDays }) => {
  // State for managing lunch breaks
  const [hasLunchBreak, setHasLunchBreak] = useState(false);
  const [lunchBreakStart, setLunchBreakStart] = useState("");
  const [lunchBreakEnd, setLunchBreakEnd] = useState("");

  // calendar events
  const [calendarEvents, setCalendarEvents] = useState([]);

  // Define a function to generate recurring events
  const generateRecurringEvents = () => {
    // Return empty recurring events if lunch break is not enabled
    if (hasLunchBreak === false) setCalendarEvents([...sampleEvents]);

    // Define a recurring rule for lunch breaks
    const lunchBreakRule = new RRule({
      freq: RRule.DAILY,
      interval: 1,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
      // Get time from lunchBreakStart, date = beginning of year.
      dtstart: new Date(initializeDateWithTimeString(lunchBreakStart)),
      until: new Date(initializeDateWithTimeString(lunchBreakEnd, 11, 31)),
    });

    const lunchBreakEvents = lunchBreakRule.all().map((date) => {
      console.log(date);
      return {
        recurring: true,
        title: "Lunch Break",
        start: new Date(date),
        end: new Date(date.getTime() + 60 * 60 * 1000), // 1 hour duration
      };
    });

    // Assign sample events + lunch break events to calendar events
    const allEvents = [...sampleEvents, ...lunchBreakEvents];
    setCalendarEvents(allEvents);
  };

  // Helper function to disable specific days
  const disableDays = ({ date, view }) => {
    if (view === "month") {
      const day = date.getDay();
      // Disable Sundays (0) and Saturdays (6)
      return day === 0 || day === 6;
    }
    return false;
  };

  useEffect(() => {
    try {
      if (workingDays["lunch_break"] === true) {
        // Do something about it
        setHasLunchBreak(true);
        // Get start and end time
        setLunchBreakStart(workingDays["lunch_break_start"]);
        setLunchBreakEnd(workingDays["lunch_break_end"]);
      }

      // generate recurring events
      generateRecurringEvents();
    } catch (e) {
      console.log(e);
    }
  }, [workingDays]);

  const theme = useTheme();
  const boxBg = useColorModeValue("white", "gray.800");

  // State and methods for adding new events =================================
  // =========================================================================
  const [showModal, setShowModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const handleSlotSelect = (slotInfo) => {
    setSelectedSlot(slotInfo);
    setShowModal(true);
  };

  const handleAddEvent = (eventData) => {
    console.log("New Event:", eventData);
    // Add your logic to handle the new event
  };
  // =========================================================================

  return (
    <Box bg={boxBg} borderRadius="lg" boxShadow="base" p="4" overflow="hidden">
      <Text fontSize="sm" color="gray.600" mb={4}>
        <Icon as={MdInfo} color="red.500" mr={2} />
        Tap on any empty slot to add a new event or inform unavailability for a
        certain time.
      </Text>
      <Calendar
        localizer={localizer}
        events={calendarEvents}
        startAccessor={(event) => {
          return new Date(event.start);
        }}
        endAccessor="end"
        style={{ height: 600 }}
        eventPropGetter={eventStyleGetter}
        views={["month", "week", "day", "agenda"]}
        defaultView="month"
        popup
        selectable
        components={{
          event: ({ event }) => (
            <Tooltip label={event.description} aria-label={event.description}>
              <span>{event.title}</span>
            </Tooltip>
          ),
        }}
        onSelectEvent={(event) => alert(event.title)}
        onSelectSlot={(slotInfo) => handleSlotSelect(slotInfo)}
        dayPropGetter={(date) => {
          const isDisabled = disableDays({ date, view: "month" });
          if (isDisabled) {
            return {
              className: "disabled-day",
              style: {
                backgroundColor: "#f0f0f0",
                pointerEvents: "none",
              },
            };
          }
          return {};
        }}
      />
      <EventForm
        isOpen={showModal}
        slotInfo={selectedSlot}
        onClose={() => setShowModal(false)}
        onAddEvent={handleAddEvent}
      />
    </Box>
  );
};

export default AdvancedCalendar;

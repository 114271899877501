import axios from "axios";

const API_URL =
  "https://core.dev.kiido.app/collaboration-api/service-provider/rdv";

const rdvService = {
  getAll: async (accessToken) => {
    try {
      // Attach the access token to the Authorization header
      const response = await axios.get(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },

  get: async ({ pk, accessToken }) => {
    try {
      // Attach the access token to the Authorization header
      const response = await axios.get(`${API_URL}/${pk}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },

  update: ({ rdv_id, obj }) => {
    // You may need to implement logout functionality depending on your Django setup.
    throw new Error("Not implemented");
  },

  softDelete: ({ rdv_id }) => {
    // You may need to implement logout functionality depending on your Django setup.
    throw new Error("Not implemented");
  },
};

export default rdvService;

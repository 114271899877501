// WorkingDaysSelector.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";

const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const WorkingDaysPrompt = () => (
  <Box mb={4}>
    <Text fontSize="lg" fontWeight="bold" mb={2}>
      Select Your Working Days
    </Text>
    <Text fontSize="sm" color="gray.600">
      Please choose the days of the week you are available for consultations.
    </Text>
  </Box>
);

const WorkingDaysSelector = ({ workingDays, onSave, loading, error }) => {
  const [selectedDays, setSelectedDays] = useState([]);

  useEffect(() => {
    console.log("workingDays =================> ", workingDays);
    const _workingDays = workingDays["working_days"] || [];
    setSelectedDays(_workingDays);
  }, [workingDays]);

  const handleDayToggle = (day) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    );

    // Save
    onSave(selectedDays);
  };

  const buttonBg = useColorModeValue("gray.200", "gray.700");
  const buttonSelectedBg = "#ff706d";
  const buttonColor = useColorModeValue("black", "white");

  return (
    <Box
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      bg={useColorModeValue("white", "gray.800")}
      boxShadow="sm"
      w="100%"
      mb={4}
    >
      <WorkingDaysPrompt />
      <ButtonGroup isAttached variant="outline" spacing={0}>
        {daysOfWeek.map((day) => (
          <Button
            key={day}
            onClick={() => handleDayToggle(day)}
            bg={selectedDays.includes(day) ? buttonSelectedBg : buttonBg}
            color={selectedDays.includes(day) ? buttonColor : "inherit"}
            flex="1"
          >
            {day}
          </Button>
        ))}
      </ButtonGroup>
      {error && <Text color="red.500">{error}</Text>}
    </Box>
  );
};

export default WorkingDaysSelector;

import { Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Box, Grid, Input, Textarea, Checkbox } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import Storage from "views/admin/profile/components/Storage";
// import { fetchUserData, updateUserData } from "./api"; // Import API functions
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import {
  selectUser,
  selectAssociation,
  selectProfessionalProfile,
  selectAccessToken,
} from "../../../selectors/authSelector";

import authService from "services/authService";

const ServiceProviderProfile = ({
  user,
  association,
  accessToken,
  professionalProfile,
}) => {
  const [userData, setUserData] = useState(null);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  useEffect(() => {
    // Mock by assigning the professionalProfile to userData
    setUserData(professionalProfile);
  }, [professionalProfile]);

  // useEffect(() => {
  //   // Fetch user data when the component mounts
  //   const fetchData = async () => {
  //     try {
  //       const data = await fetchUserData();
  //       setUserData(data);
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const handleUpdateUser = async (updatedData) => {
  //   try {
  //     // Update user data
  //     await updateUserData(updatedData);
  //     // Update the state with the new data
  //     setUserData(updatedData);
  //   } catch (error) {
  //     console.error("Error updating user data:", error);
  //   }
  // };

  if (!userData) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
    // Log
    authService.updateProfile(
      accessToken,
      { [name]: value },
      professionalProfile.id
    );
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: checked }));
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box>
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Your Profile
          </Text>
          <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
            You can edit your profile details here, every profile detail is like
            a piece of a puzzle that completes your profile.
          </Text>
          <form>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <Input
                name="email"
                placeholder="Email"
                value={userData.email || ""}
              />
              <Input
                name="first_name"
                placeholder="First Name"
                value={userData.first_name || ""}
                onChange={handleChange}
              />
              <Input
                name="first_name_fr"
                placeholder="First Name (FR)"
                value={userData.first_name_fr || ""}
                onChange={handleChange}
              />
              <Input
                name="last_name"
                placeholder="Last Name"
                value={userData.last_name || ""}
                onChange={handleChange}
              />
              <Input
                name="last_name_fr"
                placeholder="Last Name (FR)"
                value={userData.last_name_fr || ""}
                onChange={handleChange}
              />
              <Input
                name="designation"
                placeholder="Designation"
                value={userData.designation || ""}
                onChange={handleChange}
              />
              <Input
                name="designation_fr"
                placeholder="Designation (FR)"
                value={userData.designation_fr || ""}
                onChange={handleChange}
              />
              <Textarea
                name="about"
                placeholder="About"
                value={userData.about || ""}
                onChange={handleChange}
              />
              <Textarea
                name="about_fr"
                placeholder="About (FR)"
                value={userData.about_fr || ""}
                onChange={handleChange}
              />
              <Input
                name="address"
                placeholder="Address"
                value={userData.address || ""}
                onChange={handleChange}
              />
              <Input
                name="country_code"
                placeholder="Country Code"
                value={userData.country_code || ""}
                onChange={handleChange}
              />
              <Input
                name="phone_number"
                placeholder="Phone Number"
                value={userData.phone_number || ""}
                onChange={handleChange}
              />
              <Input
                name="linkedin_url"
                placeholder="LinkedIn URL"
                value={userData.linkedin_url || ""}
                onChange={handleChange}
              />
              <Input
                name="facebook_url"
                placeholder="Facebook URL"
                value={userData.facebook_url || ""}
                onChange={handleChange}
              />
              <Input
                name="instagram_url"
                placeholder="Instagram URL"
                value={userData.instagram_url || ""}
                onChange={handleChange}
              />
              <Input
                name="consultation_duration"
                placeholder="Consultation Duration"
                value={userData.consultation_duration || ""}
                onChange={handleChange}
              />
              <Input
                name="consultation_fees"
                placeholder="Consultation Fees"
                value={userData.consultation_fees || ""}
                // Validation - only allow numbers integer or decimal
                type="number"
                onChange={handleChange}
              />
            </Grid>
            <Box mt={4}>
              <button type="button" onClick={() => console.log(userData)}>
                Save Changes
              </button>
            </Box>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  association: selectAssociation(state),
  professionalProfile: selectProfessionalProfile(state),
  accessToken: selectAccessToken(state),
});

ServiceProviderProfile.propTypes = {
  user: PropTypes.object,
  association: PropTypes.object,
  professionalProfile: PropTypes.object,
  accessToken: PropTypes.string,
};

export default connect(mapStateToProps)(ServiceProviderProfile);

// services/workingDaysService.js
import axios from "axios";

export const fetchWorkingDaysAPI = async ({ accessToken }) => {
  const response = await axios.get(
    "https://core.dev.kiido.app/collaboration-api/service-provider/calendar/",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.status === 200) {
    throw new Error("Failed to fetch working days");
  }

  return response.data;
};

export const saveWorkingDaysAPI = async ({ accessToken, days, calendarId }) => {
  const response = await axios.patch(
    `https://core.dev.kiido.app/collaboration-api/service-provider/calendar/${calendarId}/`,
    { working_days: days },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.status === 200) {
    throw new Error("Failed to save working days");
  }

  return response.data;
};

const sampleEvents = [
  {
    id: 1,
    title: "Team Meeting",
    start: "2024-05-20T09:00:00",
    end: "2024-05-20T10:00:00",
    description: "Monthly team sync-up meeting",
    location: "Conference Room A",
    allDay: false,
  },
  {
    id: 2,
    title: "Project Kickoff",
    start: "2024-05-21T11:00:00",
    end: "2024-05-21T12:30:00",
    description: "Kickoff meeting for the new project",
    location: "Online",
    allDay: false,
  },
  {
    id: 3,
    title: "Consultation Session",
    start: "2024-05-22T12:00:00",
    end: "2024-05-22T13:00:00",
    description: "",
    location: "Cafeteria",
    allDay: false,
  },
  {
    id: 4,
    title: "Webinar on React",
    start: "2024-05-23T14:00:00",
    end: "2024-05-23T15:30:00",
    description: "An in-depth webinar on advanced React topics",
    location: "Online",
    allDay: false,
  },
  {
    id: 5,
    title: "Client Presentation",
    start: "2024-05-24T10:00:00",
    end: "2024-05-24T11:00:00",
    description: "Presenting the project progress to the client",
    location: "Client's Office",
    allDay: false,
  },
  {
    id: 6,
    title: "All Day Event",
    start: "2024-05-25T00:00:00",
    end: "2024-05-25T23:59:59",
    description: "An event that spans the entire day",
    location: "",
    allDay: true,
  },
];

export default sampleEvents;

const initialState = {
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  user: null,
  professionalProfile: null,
  association: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      console.log("Handling LOGIN_SUCCESS action:", action.payload);
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        user: action.payload.user,
        professionalProfile: action.payload.professional_profile,
        association: action.payload.association,
        error: null,
      };
    case "LOGIN_FAILURE":
      console.log("Handling LOGIN_FAILURE action:", action.payload);
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
        user: null,
        professionalProfile: null,
        association: null,
        error: action.payload.error,
      };
    // Add more cases for other actions like logout, token refresh, etc.
    default:
      return state;
  }
};

export default authReducer;

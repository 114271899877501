import { applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// Reducers
import authReducer from "reducers/authReducer"; // Your root reducer
import collaboratorReducer from "reducers/collaboratorReducer"; // Your collaborator reducer
import workingDaysReducer from "reducers/workingDaysReducer";
import languageReducer from "reducers/languageReducer";

// Combine reducers if needed
const rootReducer = combineReducers({
  auth: authReducer,
  collaborator: collaboratorReducer,
  workingDays: workingDaysReducer,
  language: languageReducer,
  // Add more reducers here if needed
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

import React from "react";

// Chakra imports
import { Flex, Image, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

// Redux
import { connect } from "react-redux";
import { selectAssociation } from "selectors/authSelector";

const SidebarBrand = ({ association }) => {
  // const association = props.association;

  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column">
      {/* <HorizonLogo h="26px" w="175px" my="32px" color={logoColor} /> */}
      <Image
        src={association?.logo}
        alt={`${association?.name}'s Logo`}
        h="40px"
        w="solid"
        my="8px"
      />
      <HSeparator mb="20px" />
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  association: selectAssociation(state),
});

export default connect(mapStateToProps)(SidebarBrand);

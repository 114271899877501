import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
} from "@chakra-ui/react";

const EventForm = ({ isOpen, onClose, onAddEvent, slotInfo }) => {
  console.log("==================>", slotInfo);
  // If slotInfo is not null, set the start time
  // and end time of the event form to the selected slot
  //   if (slotInfo) {
  //     const { start, end } = slotInfo;
  //     slotInfo = {
  //       startTime: start,
  //       endTime: end,
  //     };
  //   } else {
  //     slotInfo = null;
  //   }

  const [eventData, setEventData] = useState({
    topic: "",
    type: "",
    startTime: "",
    endTime: "",
    allDay: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value,
    });
  };

  const handleToggleChange = () => {
    setEventData({
      ...eventData,
      allDay: !eventData.allDay,
    });
  };

  const handleSubmit = () => {
    onAddEvent(eventData);
    setEventData({
      topic: "",
      type: "",
      startTime: "",
      endTime: "",
      allDay: false,
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Event</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Topic</FormLabel>
            <Input
              type="text"
              name="topic"
              value={eventData.topic}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Type</FormLabel>
            <Select
              name="type"
              value={eventData.type}
              onChange={handleInputChange}
            >
              <option value="Out of office">Out of office</option>
              <option value="Consultation">Consultation</option>
              <option value="Personal Reason">Personal Reason</option>
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Start Time</FormLabel>
            <Input
              type="datetime-local"
              name="startTime"
              value={eventData.startTime}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>End Time</FormLabel>
            <Input
              type="datetime-local"
              name="endTime"
              value={eventData.endTime}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>All Day</FormLabel>
            <Switch
              name="allDay"
              isChecked={eventData.allDay}
              onChange={handleToggleChange}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EventForm;

// reducers/workingDaysReducer.js
import {
  SET_WORKING_DAYS,
  FETCH_WORKING_DAYS,
  FETCH_WORKING_DAYS_SUCCESS,
  FETCH_WORKING_DAYS_FAILURE,
} from "../actions/types/workingDays.types";

const initialState = {
  workingDays: [],
  loading: false,
  error: null,
};

const workingDaysReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WORKING_DAYS:
      return {
        ...state,
        workingDays: action.payload,
      };
    case FETCH_WORKING_DAYS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_WORKING_DAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        workingDays: action.payload,
      };
    case FETCH_WORKING_DAYS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default workingDaysReducer;

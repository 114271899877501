import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdDashboard,
  MdCalendarToday,
  MdMeetingRoom,
  MdLock,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import ServiceProviderCalendar from "views/admin/calendar";
import ServiceProviderProfile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import MediationManagement from "views/admin/appointmentCheckin";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Overview",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Calendar",
    layout: "/admin",
    path: "/calendar",
    icon: (
      <Icon as={MdCalendarToday} width="20px" height="20px" color="inherit" />
    ),
    component: ServiceProviderCalendar,
    secondary: true,
  },
  {
    name: "Appointments",
    layout: "/admin",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: "/appointment",
    component: DataTables,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: (
      <Icon as={MdMeetingRoom} width="20px" height="20px" color="inherit" />
    ),
    component: ServiceProviderProfile,
  },
  {
    name: "Appointment Checkin",
    layout: "/admin",
    path: "/appointment-checkin/:appointmentId",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: MediationManagement,
    visible: false,
  },
  {
    name: "Login",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    visible: false,
  },
];

export default routes;
